import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { work } from "./portfolio"

interface WorkProps {
  work: string
}

const Video = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: color 0.3s;
  margin-bottom: 20px;
  display: inline-block;

  &:hover {
    color: white;
  }
`

export function Work(props: WorkProps) {
  const video = work.find(w => w.id == props.work)
  if (!video) return null

  return (
    <>
      <StyledLink to="/">&#8249; Back</StyledLink>
      <Video>
        <iframe
          src={video.video}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Video>
    </>
  )
}
